* {
  margin: 0px;
  padding: 0px;
  max-height: 100%;
  box-sizing: border-box;
}

.approved-row {
  background-color: rgb(172, 237, 172) !important;
}


.tooltip-content {
  display: flex;
  flex-direction: column;
}

.spinner-antd {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 16%;
}

.tooltip-entry {
  display: flex;
  justify-content: space-between;
  padding: 5px 0;
  border-bottom: 1px solid #e0e0e0;
}

.tooltip-entry:last-child {
  border-bottom: none;
}

.yellow-row {
  background-color: yellow !important;
}

th.ant-table-cell {
  min-width: 200px;
}

.star-red {
  position: absolute;
  color: red;
  margin-left: 104px;
  margin-top: 12px;
}

/* tbody > tr > td:nth-child(8) {
  background-color: rgba(81, 181, 176);
  color: black;
} */
tbody>tr>td:nth-child(14) {
  background-color: rgba(184, 237, 104);
  color: black;
}

.green-row {
  background-color: rgb(89, 181, 89) !important;
}

.red-row {
  background-color: rgb(202, 84, 84) !important;
}

.pending-row {
  background-color: rgb(105, 125, 184) !important;
}

.work-row {
  background-color: rgb(54, 121, 58) !important;
}

.work-row {
  background-color: rgb(189, 80, 183) !important;
}

.employee-name {
  font-weight: 500;
}

.employee-hours {
  color: #888;
}

.outer-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  position: absolute;
}

.emp-main-div {
  display: flex;
  align-items: center;
  width: 100%;
  height: auto;
  background-color: #f7f9ff;
}

.menu-div {
  height: auto;
  background-color: white;
  min-width: auto;
  display: flex;
  width: 100%
}

.menu-div ul {
  width: auto;
}

.ck-editor__editable_inline {
  min-height: 300px;
}

.total-size {
  font-size: 2rem;
  margin-left: 45px;
  color: #2723cd;
}

.logout-button:hover::before {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: black;
}

.total-lengthPortal {
  display: flex;
  gap: 25px;
  font-size: 1rem;
}

.portal {
  color: red;
}

.dash-time {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
}

.form-container {
  width: 100%;
  height: 100%;
  background-color: #f7f9ff;
  padding: 2rem;
  /* display: flex;
  align-items: center;
  justify-content: center; */
  /* margin-top: 10vh */
}

.SalecampusForm-data-os {
  width: 100%;
  background-color: #f7f9ff;
  padding: 2rem 4rem;
}

.hidden {
  display: none;
}

.form-div {
  width: 57%;
  background-color: #F7F9FF;
  height: 100%;
  margin-right: 48%;
}

.emp-div-flex {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 2rem;
}

.form-group {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.form-control {
  padding: 6%;
  padding-left: 10px;
  padding-right: 4%;
  border-radius: 5px;
  padding-top: 15px;
  padding-bottom: 15px;
  border-width: 1px solid #094781;
  background-color: #ffffff;
  border: 1.69622px solid #d8d6d6;
  border-radius: 4.24055px;
}

.emp-label {
  font-weight: bold;
  color: #094781;
  margin-top: 3%;
  margin-bottom: 2%;
}



.upwork {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 16.83px;
  line-height: 15px;
  letter-spacing: 0.02em;
  min-width: fit-content;
  margin-left: 4%;
  border: 1px solid black;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-inline: 4px;
  border-radius: 15px;
}

.add-div {
  display: flex;
  flex-direction: column;
  margin: 1px 25px;
  justify-content: flex-start;
}

.backlog-table table {
  width: auto
}

.assign-task ul.ant-pagination {
  justify-content: center;
}

.assign-task table {
  width: auto;
}

.emp-mrng {
  display: flex;
  width: 100%;
  height: auto;
  background-color: #f7f9ff;
}

.add-heading {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 26.1px;
  line-height: 39px;
  letter-spacing: 0.02em;
  color: #094781;
}

.go-button {
  background-color: #04AA6D;
  border: none;
  color: white;
  padding: 6px 19px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 15px;
}

.add-label {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 15.3508px;
  line-height: 23px;
  color: #094781;
  margin-top: 8px;
}

.add-input {
  background: #ffffff;
  border: 1.69622px solid #d8d6d6;
  border-radius: 4.24055px;
  box-sizing: border-box;
  min-height: 53.43px;
  margin-top: 10px;
  padding-inline: 10px;
}

.assign-div {
  display: flex;
  flex-direction: row;
  margin-top: 35px;
  width: 100%;
  gap: 4px
}

.time-table-rs {
  display: flex;
  flex-direction: row;
  overflow-x: scroll;
  max-width: 1000px;
}

.task-color-rs {
  max-width: 300px;
}

.roles-rs {
  padding: 7px;
  min-width: 200px;
  border-radius: 6px;
}

.fiters-dashboard-rs {
  display: flex;
  gap: 12px;
}

.mrng-newDashboard {
  display: flex;
  justify-content: center;
  gap: 10px;
  background: #329d32;
  color: white;
  padding: 6px;
  font-weight: 700;
}

.evng-newDashboard {
  display: flex;
  justify-content: center;
  gap: 10px;
  padding: 6px;
  font-weight: 700;
  border-bottom: 2px solid black;
}

.table-new-row {
  background-color: #a8b7ef;
}

.all-total-time {
  display: flex;
  justify-content: space-evenly;
}

.row-success {
  background-color: #07ef3d;
  /* Light green background for success */
}

.emp-name_rs {
  color: red;
  margin-bottom: 0.5rem;
}



.emp-table-rs {
  margin-bottom: 2rem;
}

.total-time-report {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
  border: 1px solid rgb(204, 204, 204);
  border-radius: 5px;
  width: 20%;
  text-align: center;
  justify-content: center;
  margin-bottom: 1rem;
  margin-top: 1rem;
}

/* .time-row-rs .ant-table-content {
  height: 600px;
  overflow: auto;
} */

.time-table-data:hover {
  background: #ccff00ad;
}

.login-form-button {
  width: 40%;
  height: 100%;
  border-radius: 25px;
  background-color: rgb(9, 71, 129);
  padding-right: 10%;
  padding-left: 10%;
  color: white;
  text-align: center;
  font-size: 18px;
  font-weight: 800;
}

.time-row-rs {
  display: flex;
  flex-direction: column;
}

.time-table-data {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  margin: 10px 0px 20px;
  padding: 10px;
  border: 1px solid rgb(204, 204, 204);
  border-radius: 5px;
  width: 100%;
}

.login-img {
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgb(9, 71, 129);
}

.b2b-login {
  font-size: 48px;
  font-weight: bold;
  color: rgb(9, 71, 129);
}

.emp-form-login {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.login-main {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.phase-mod {
  display: flex;
  flex-direction: row;
  width: auto;
  gap: 3px;
}

.mrng-phase {
  display: flex;
  flex-direction: column;
  width: 33.3%;
}

.round-button {
  display: inline-block;
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 50%;
  text-align: center;
  background-color: #f1f1f1;
  color: #333;
  font-size: 24px;
  font-weight: bold;
  text-decoration: none;
  border: 1px solid #ddd;
  cursor: pointer;
  margin-top: 15px;
  margin-right: "15px"

}

.round-button:hover {
  background-color: #ddd;
}


.add-button {
  padding: 6px;
  width: auto;
  background: #094781;
  border-radius: 4.96885px;
  margin-top: 24px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 18.7263px;
  line-height: 28px;
  letter-spacing: 0.02em;
  color: #ffffff;
}

.nav-dropDown {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: 10.83px;
  line-height: 19px;
  letter-spacing: 0.02em;
  padding: 0;
  color: #bdbdbd;
}

.go-btn {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16.83px;
  line-height: 25px;
  letter-spacing: 0.02em;
  background: #154880;
  border-radius: 5px;
  color: #ffffff;
}

.form-leave {
  padding: 6%;
  padding-top: 15px;
  padding-bottom: 15px;
  border: 1.69622px solid #d8d6d6;
  border-radius: 4.24055px;
  width: 100%;
}

.nav-proj-btn {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 13.292px;
  line-height: 20px;
  letter-spacing: 0.02em;
  color: #ffffff;
  text-shadow: 0px 2px 9px rgba(0, 0, 0, 0.09);
  background: #fd7c50;
  border: 1px solid #454545;
  border-radius: 50px;
}

.input-add {
  width: 100%;
  padding: 5px;
  margin-bottom: 15px;
  line-height: 2rem;
  border-radius: 7px;
  border: 1px solid;
}

.nav-date {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16.83px;
  line-height: 25px;
  letter-spacing: 0.02em;
  color: #2a2a2a;
}

.nav-hrs-estimate {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16.83px;
  line-height: 25px;
  letter-spacing: 0.02em;
  color: #2a2a2a;
  border: 1px solid #bebebe;
  border-radius: 99px;
  width: auto;
  height: auto;
  left: 1141px;
  top: 165px;
  padding: 4px 20px 4px;
  display: inline-flex;
  align-items: center;
}

.proj-person {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 13.15px;
  line-height: 20px;
  letter-spacing: 0.02em;
  color: #154880;
  margin-bottom: 1.5%;
  margin-left: 2%;
}

.table-data {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 10.15px;
  line-height: 15px;
  letter-spacing: 0.02em;
  color: #676767;
}

.dashboard-main {
  display: flex;
  margin-top: 2rem;
  gap: 2rem;
}

.containerStyle {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.totalLeaveStyle {
  font-size: 16px;
  line-height: 1.5;
  font-weight: bold;
}

.uncertainLeaveStyle {
  font-size: 16px;
  line-height: 1.5;
  font-style: italic;
}

.regularLeaveStyle {
  font-size: 16px;
  line-height: 1.5;
  font-style: italic;
}


/* salecampus css starts */
css .campus_form {
  width: 90%;
  margin: 0 auto;
}

.ant-select-selector {
  border-style: none !important;
  margin-top: 9px !important;
}

.placeholder-color .ant-select-selection-placeholder {
  color: black
}

.placeholder-color {
  display: flex;
  gap: 10px;
  width: 70%;
}

.shifting-change thead.ant-table-thead tr th:nth-child(1),
.shifting-change thead.ant-table-thead tr th:nth-child(2),
.shifting-change thead.ant-table-thead tr th:nth-child(3),
.shifting-change thead.ant-table-thead tr th:nth-child(4),
.shifting-change thead.ant-table-thead tr th:nth-child(5),
.shifting-change thead.ant-table-thead tr th:nth-child(6),
.shifting-change thead.ant-table-thead tr th:nth-child(7),
.shifting-change thead.ant-table-thead tr th:nth-child(8) {
  min-width: 100px;
  padding: 0 4px;
}

.dashboard-handle thead.ant-table-thead tr th:nth-child(1),
.dashboard-handle thead.ant-table-thead tr th:nth-child(2),
.dashboard-handle thead.ant-table-thead tr th:nth-child(3),
.dashboard-handle thead.ant-table-thead tr th:nth-child(4),
.dashboard-handle thead.ant-table-thead tr th:nth-child(5),
.dashboard-handle thead.ant-table-thead tr th:nth-child(6),
.dashboard-handle thead.ant-table-thead tr th:nth-child(7) {
  min-width: 100px;
  padding: 0 10px;
}

.shifting-change table {
  width: auto;
}

.cat-table thead.ant-table-thead tr th:nth-child(1),
.cat-table thead.ant-table-thead tr th:nth-child(2),
.cat-table thead.ant-table-thead tr th:nth-child(3) {
  min-width: 100px;
  padding: 0 4px;
}

.cat-table table {
  width: auto;
}

.shifting-change table tbody.ant-table-tbody td.ant-table-cell {
  text-align: center;
  padding: 0 4px;
}

.clientSheetTlTask thead.ant-table-thead tr th:nth-child(1),
.clientSheetTlTask thead.ant-table-thead tr th:nth-child(2),
.clientSheetTlTask thead.ant-table-thead tr th:nth-child(3),
.clientSheetTlTask thead.ant-table-thead tr th:nth-child(4),
.clientSheetTlTask thead.ant-table-thead tr th:nth-child(5),
.clientSheetTlTask thead.ant-table-thead tr th:nth-child(6),
.clientSheetTlTask thead.ant-table-thead tr th:nth-child(7),
.clientSheetTlTask thead.ant-table-thead tr th:nth-child(8),
.clientSheetTlTask thead.ant-table-thead tr th:nth-child(9),
.clientSheetTlTask thead.ant-table-thead tr th:nth-child(10),
.clientSheetTlTask thead.ant-table-thead tr th:nth-child(11),
.clientSheetTlTask thead.ant-table-thead tr th:nth-child(12),
.clientSheetTlTask thead.ant-table-thead tr th:nth-child(13) {
  min-width: 100px;
  padding: 0 4px;
}

.clientSheetTlTask table tbody.ant-table-tbody td.ant-table-cell {
  text-align: center;
  padding: 0 4px;
  width: 20px;
}

.clientSheetTlTask ul.ant-pagination.ant-table-pagination.ant-table-pagination-right.css-dev-only-do-not-override-txh9fw {
  display: none;
}

.saleCampus-form thead.ant-table-thead tr th:nth-child(1),
.saleCampus-form thead.ant-table-thead tr th:nth-child(2),
.saleCampus-form thead.ant-table-thead tr th:nth-child(3),
.saleCampus-form thead.ant-table-thead tr th:nth-child(4),
.saleCampus-form thead.ant-table-thead tr th:nth-child(5),
.saleCampus-form thead.ant-table-thead tr th:nth-child(6),
.saleCampus-form thead.ant-table-thead tr th:nth-child(7),
.saleCampus-form thead.ant-table-thead tr th:nth-child(8),
.saleCampus-form thead.ant-table-thead tr th:nth-child(9),
.saleCampus-form thead.ant-table-thead tr th:nth-child(10),
.saleCampus-form thead.ant-table-thead tr th:nth-child(11),
.saleCampus-form thead.ant-table-thead tr th:nth-child(12),
.saleCampus-form thead.ant-table-thead tr th:nth-child(13),
.saleCampus-form thead.ant-table-thead tr th:nth-child(14) {
  min-width: 100px;
  padding: 0 10px;
}

.saleCampus-form table {
  width: auto;
}

.shift-table thead.ant-table-thead tr th:nth-child(1),
.shift-table thead.ant-table-thead tr th:nth-child(2),
.shift-table thead.ant-table-thead tr th:nth-child(3),
.shift-table thead.ant-table-thead tr th:nth-child(4),
.shift-table thead.ant-table-thead tr th:nth-child(5),
.shift-table thead.ant-table-thead tr th:nth-child(6),
.shift-table thead.ant-table-thead tr th:nth-child(7) {
  min-width: 100px;
  padding: 0 10px;
}

.shift-table table {
  width: auto;
}

.shift-table ul.ant-pagination {
  justify-content: center;
}

.leave-Page thead.ant-table-thead tr th:nth-child(1),
.leave-Page thead.ant-table-thead tr th:nth-child(2),
.leave-Page thead.ant-table-thead tr th:nth-child(3),
.leave-Page thead.ant-table-thead tr th:nth-child(4),
.leave-Page thead.ant-table-thead tr th:nth-child(5),
.leave-Page thead.ant-table-thead tr th:nth-child(6),
.leave-Page thead.ant-table-thead tr th:nth-child(7),
.leave-Page thead.ant-table-thead tr th:nth-child(8) {
  min-width: 100px;
}

.pro-list table {
  width: auto;
}

.leave-Page table {
  width: auto;
}

.status-all table {
  width: auto;
}

.leave-Page ul.ant-pagination {
  justify-content: center;
}

.view-phase thead.ant-table-thead tr th:nth-child(1),
.view-phase thead.ant-table-thead tr th:nth-child(1),
.view-phase thead.ant-table-thead tr th:nth-child(1) {
  min-width: 100px;
  padding: 0 10px;
}

.backlog-table thead.ant-table-thead tr th:nth-child(1),
.backlog-table thead.ant-table-thead tr th:nth-child(2),
.backlog-table thead.ant-table-thead tr th:nth-child(3),
.backlog-table thead.ant-table-thead tr th:nth-child(4),
.backlog-table thead.ant-table-thead tr th:nth-child(5),
.backlog-table thead.ant-table-thead tr th:nth-child(6),
.backlog-table thead.ant-table-thead tr th:nth-child(7),
.backlog-table thead.ant-table-thead tr th:nth-child(8),
.backlog-table thead.ant-table-thead tr th:nth-child(9),
.backlog-table thead.ant-table-thead tr th:nth-child(10),
.backlog-table thead.ant-table-thead tr th:nth-child(11) {
  min-width: 100px;
  padding: 0 10px;
}

.assign-task thead.ant-table-thead tr th:nth-child(1),
.assign-task thead.ant-table-thead tr th:nth-child(2),
.assign-task thead.ant-table-thead tr th:nth-child(4),
.assign-task thead.ant-table-thead tr th:nth-child(5),
.assign-task thead.ant-table-thead tr th:nth-child(6),
.assign-task thead.ant-table-thead tr th:nth-child(7),
.assign-task thead.ant-table-thead tr th:nth-child(8) {
  min-width: 100px;
  padding: 0 10px;
}

.employee-list thead.ant-table-thead tr th:nth-child(1),
.employee-list thead.ant-table-thead tr th:nth-child(2),
.employee-list thead.ant-table-thead tr th:nth-child(3),
.employee-list thead.ant-table-thead tr th:nth-child(4),
.employee-list thead.ant-table-thead tr th:nth-child(5),
.employee-list thead.ant-table-thead tr th:nth-child(6),
.employee-list thead.ant-table-thead tr th:nth-child(7),
.employee-list thead.ant-table-thead tr th:nth-child(8) {
  min-width: 100px;
  padding: 0 10px;
}

.employee-list table {
  width: auto;
}

.employee-list ul.ant-pagination {
  justify-content: center;
}

/* .dashboard-handle thead.ant-table-thead tr th:nth-child(1),
.dashboard-handle thead.ant-table-thead tr th:nth-child(2),
.dashboard-handle thead.ant-table-thead tr th:nth-child(3),
.dashboard-handle thead.ant-table-thead tr th:nth-child(4),
.dashboard-handle thead.ant-table-thead tr th:nth-child(5),
.dashboard-handle thead.ant-table-thead tr th:nth-child(6),
.dashboard-handle thead.ant-table-thead tr th:nth-child(7) {
  min-width: 100px;
  padding: 0 10px;
} */

.add-button-2 {
  padding: 6px;
  width: 25%;
  background: #094781;
  border-radius: 4.96885px;
  margin-top: 24px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 18.7263px;
  line-height: 28px;
  letter-spacing: 0.02em;
  color: #ffffff;
}

.evening-handle table thead.ant-table-thead tr th:nth-child(1),
.evening-handle table thead.ant-table-thead tr th:nth-child(2),
.evening-handle table thead.ant-table-thead tr th:nth-child(3),
.evening-handle table thead.ant-table-thead tr th:nth-child(5),
.evening-handle table thead.ant-table-thead tr th:nth-child(6),
.evening-handle table thead.ant-table-thead tr th:nth-child(7),
.evening-handle table thead.ant-table-thead tr th:nth-child(8),
.evening-handle table thead.ant-table-thead tr th:nth-child(9),
.evening-handle table thead.ant-table-thead tr th:nth-child(10) {
  min-width: 100px;
  padding: 0 10px;
}



.evening-handle ul.ant-pagination {
  justify-content: center;
}

.saleInfo-form thead.ant-table-thead tr th:nth-child(1),
.saleInfo-form thead.ant-table-thead tr th:nth-child(2),
.saleInfo-form thead.ant-table-thead tr th:nth-child(3),
.saleInfo-form thead.ant-table-thead tr th:nth-child(4),
.saleInfo-form thead.ant-table-thead tr th:nth-child(5),
.saleInfo-form thead.ant-table-thead tr th:nth-child(6),
.saleInfo-form thead.ant-table-thead tr th:nth-child(7),
.saleInfo-form thead.ant-table-thead tr th:nth-child(8),
.saleInfo-form thead.ant-table-thead tr th:nth-child(9),
.saleInfo-form thead.ant-table-thead tr th:nth-child(10),
.saleInfo-form thead.ant-table-thead tr th:nth-child(11),
.saleInfo-form thead.ant-table-thead tr th:nth-child(12),
.saleInfo-form thead.ant-table-thead tr th:nth-child(13) {
  min-width: 100px;
  padding: 0 10px;
}

.saleInfo-form table {
  width: auto;
}

.saleInfo-form ul.ant-pagination {
  justify-content: center;
}

.evening-dashboard thead.ant-table-thead tr th:nth-child(9) {
  min-width: 0px;
}

.leave-table thead.ant-table-thead tr th:nth-child(1),
.leave-table thead.ant-table-thead tr th:nth-child(2),
.leave-table thead.ant-table-thead tr th:nth-child(3),
.leave-table thead.ant-table-thead tr th:nth-child(4),
.leave-table thead.ant-table-thead tr th:nth-child(5),
.leave-table thead.ant-table-thead tr th:nth-child(6),
.leave-table thead.ant-table-thead tr th:nth-child(7),
.leave-table thead.ant-table-thead tr th:nth-child(8),
.leave-table thead.ant-table-thead tr th:nth-child(9) {
  min-width: 100px;
  padding: 0 10px;
}

.leave-page thead.ant-table-thead tr th:nth-child(1),
.leave-page thead.ant-table-thead tr th:nth-child(2),
.leave-page thead.ant-table-thead tr th:nth-child(3),
.leave-page thead.ant-table-thead tr th:nth-child(4),
.leave-page thead.ant-table-thead tr th:nth-child(5),
.leave-page thead.ant-table-thead tr th:nth-child(6),
.leave-page thead.ant-table-thead tr th:nth-child(7),
.leave-page thead.ant-table-thead tr th:nth-child(8),
.leave-page thead.ant-table-thead tr th:nth-child(9) {
  min-width: 100px;
  padding: 0 10px;
}

.leave-page table {
  width: auto;
}

.view-term thead.ant-table-thead tr th:nth-child(1),
.view-term thead.ant-table-thead tr th:nth-child(2),
.view-term thead.ant-table-thead tr th:nth-child(3),
.view-term thead.ant-table-thead tr th:nth-child(4) {
  min-width: 100px;
  padding: 0 10px;
}

.view-term table {
  width: auto;
}

.leave-table table {
  width: auto;
}

.view-term ul.ant-pagination {
  justify-content: left;
}

.leave-table ul.ant-pagination {
  justify-content: center;
}

.infotech-form thead.ant-table-thead tr th:nth-child(1),
.infotech-form thead.ant-table-thead tr th:nth-child(2),
.infotech-form thead.ant-table-thead tr th:nth-child(3),
.infotech-form thead.ant-table-thead tr th:nth-child(4),
.infotech-form thead.ant-table-thead tr th:nth-child(5),
.infotech-form thead.ant-table-thead tr th:nth-child(6),
.infotech-form thead.ant-table-thead tr th:nth-child(7),
.infotech-form thead.ant-table-thead tr th:nth-child(8),
.infotech-form thead.ant-table-thead tr th:nth-child(9),
.infotech-form thead.ant-table-thead tr th:nth-child(10),
.infotech-form thead.ant-table-thead tr th:nth-child(11),
.infotech-form thead.ant-table-thead tr th:nth-child(12),
.infotech-form thead.ant-table-thead tr th:nth-child(13),
.infotech-form thead.ant-table-thead tr th:nth-child(14),
.infotech-form thead.ant-table-thead tr th:nth-child(15),
.infotech-form thead.ant-table-thead tr th:nth-child(16),
.infotech-form thead.ant-table-thead tr th:nth-child(17) {
  min-width: 100px;
  padding: 0 10px;
}

.infotech-form table {
  width: auto;
}

.mrng-table thead.ant-table-thead tr th:nth-child(1),
.mrng-table thead.ant-table-thead tr th:nth-child(2),
.mrng-table thead.ant-table-thead tr th:nth-child(3),
.mrng-table thead.ant-table-thead tr th:nth-child(4),
.mrng-table thead.ant-table-thead tr th:nth-child(5),
.mrng-table thead.ant-table-thead tr th:nth-child(6),
.mrng-table thead.ant-table-thead tr th:nth-child(7),
.mrng-table thead.ant-table-thead tr th:nth-child(8),
.mrng-table thead.ant-table-thead tr th:nth-child(9) {
  min-width: 100px;
  padding: 0 10px;
}

.mrng-table table {
  width: auto;
}

.mrng-table ul.ant-pagination {
  justify-content: center;
}

.adjust-inputs {
  height: 27px !important;
  min-height: 32px;
  margin-top: 0px;
  background: #ffffff;
  border: 1px solid #d9d9d9;
  border-radius: 6px;
}

.SalecampusForm-input-os input,
.SalecampusForm-input-os select {
  width: 100%;
  padding: 12px;
  border-radius: 5px;
  border: 1px solid gray;
}

.additional-notes {
  width: 100%;
  padding: 12px;
  border-radius: 5px;
  border: 1px solid gray;
}

.SalecampusForm-col-os {
  padding-bottom: 2rem;
}

.gender {
  position: relative;
}

.gender select {
  width: 100%;
  /* margin-top: 15px; */
  padding: 12px;
  border-radius: 5px;
  border: 1px solid gray;
  margin-top: 30px;
}

.SalecampusForm-submit-os button {
  width: 40%;
  padding: 10px;
  background: #071c4d;
  color: #fff;
  font-size: 16px;
  border: 0;
  border-radius: 10px;
  font-family: inherit;
  font-weight: 500;
  cursor: pointer;
}

.gender1-radio {
  width: 7%;
  display: flex;
  gap: 10px;
  align-items: center;
}

.SalecampusForm-data-os h2 {
  padding-bottom: 1rem;
  color: #071c4d;
  font-size: 27px;
}

.error-message-os {
  color: red;
  font-size: 16px;
  font-weight: 400;
  padding: 8px 0;
}

.mrng-tas {
  color: rgb(9, 71, 129);
  font-size: 32px;
  font-weight: bold;
  margin-bottom: 1rem;
}

.SalecampusForm-radio-os {
  display: flex;
  align-items: center;
}

.SalecampusForm-radio-os label {
  display: flex;
  align-items: center;
  gap: 12px;
  cursor: pointer;
}

.SalecampusForm-radio-os label input {
  cursor: pointer;
}

.SalecampusForm-radio-row-os {
  display: flex;
  align-items: center;
  gap: 1rem 2rem;
  padding-bottom: 1.5rem;
  flex-wrap: wrap;
}

/* .SalecampusFormList-default-os {
  padding: 3rem;
} */

/* table css starts */
table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td,
th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

tr:nth-child(even) {
  background-color: #dddddd;
}

/* table css ends */
/* salecampus css end */

.SalesInfoForm-radio-os label {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  width: 30%;
}

.SalesInfoForm-radio-os {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding-bottom: 0.2rem;
}

.SalesInfoForm-input-os input {
  width: 100%;
  padding: 8px 12px;
  border-radius: 5px;
  border: 1px solid gray;
}

.SalesInfoForm-input-os {
  width: 70%;
}

.SalesInfoForm-input-os input:disabled {
  opacity: 0.5;
  cursor: no-drop;
}

/* styles.css */

.interested {
  background-color: #bdfbab !important;
}

.not-interested {
  background-color: #f64c4c !important;
}

.hopefully {
  background-color: #f8e07f !important;
}

.not-picked {
  background-color: #f19b2b !important;
}

.enrolled {
  background-color: #92b3fb !important;
}

/* This ensures that every row, regardless of whether it is even or odd, takes the color based on its class and doesn't get shaded differently */
/* .ant-table-row.interested,
.ant-table-row.not-interested,
.ant-table-row.hopefully,
.ant-table-row.not-picked,
.ant-table-row.enrolled {
  background-color: inherit;
} */

@media screen and (max-width: 767px) {
  .login-img {
    display: none !important;
  }

  .login-main {
    width: 100% !important;
  }

  .login-form-button {
    width: 100% !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
  }

  .email-input {
    width: 100% !important;
  }

  .outer-container {
    height: unset !important;
    margin-top: 5rem !important;
  }

  .login-form {
    width: 100% !important;
  }

  .emp-form-login {
    padding: 2rem;
  }
}

@media screen and (min-width: 767px) and (max-width: 1202px) {
  .form-div {
    width: 100%;
    background-color: #F7F9FF;
    height: 100%;
    margin-right: 48%;
  }
}


@media screen and (min-width: 1024px) and (max-width: 1440px) {

  .dashboard-handle thead.ant-table-thead tr th:nth-child(1),
  .dashboard-handle thead.ant-table-thead tr th:nth-child(2),
  .dashboard-handle thead.ant-table-thead tr th:nth-child(3),
  .dashboard-handle thead.ant-table-thead tr th:nth-child(4),
  .dashboard-handle thead.ant-table-thead tr th:nth-child(5),
  .dashboard-handle thead.ant-table-thead tr th:nth-child(6),
  .dashboard-handle thead.ant-table-thead tr th:nth-child(7) {
    min-width: 150px;
    padding: 0 10px;
  }
}

@media screen and (min-width: 1450px) and (max-width: 1700px) {

  .dashboard-handle thead.ant-table-thead tr th:nth-child(1),
  .dashboard-handle thead.ant-table-thead tr th:nth-child(2),
  .dashboard-handle thead.ant-table-thead tr th:nth-child(3),
  .dashboard-handle thead.ant-table-thead tr th:nth-child(4),
  .dashboard-handle thead.ant-table-thead tr th:nth-child(5),
  .dashboard-handle thead.ant-table-thead tr th:nth-child(6),
  .dashboard-handle thead.ant-table-thead tr th:nth-child(7) {
    min-width: 160px;
    padding: 0 10px;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1440px) {

  .evening-handle table thead.ant-table-thead tr th:nth-child(1),
  .evening-handle table thead.ant-table-thead tr th:nth-child(2),
  .evening-handle table thead.ant-table-thead tr th:nth-child(3),
  .evening-handle table thead.ant-table-thead tr th:nth-child(5),
  .evening-handle table thead.ant-table-thead tr th:nth-child(6),
  .evening-handle table thead.ant-table-thead tr th:nth-child(7),
  .evening-handle table thead.ant-table-thead tr th:nth-child(8),
  .evening-handle table thead.ant-table-thead tr th:nth-child(9),
  .evening-handle table thead.ant-table-thead tr th:nth-child(10) {
    min-width: 150px;
    padding: 0 10px;
  }
}

@media screen and (min-width: 1450px) and (max-width: 1700px) {

  .evening-handle table thead.ant-table-thead tr th:nth-child(1),
  .evening-handle table thead.ant-table-thead tr th:nth-child(2),
  .evening-handle table thead.ant-table-thead tr th:nth-child(3),
  .evening-handle table thead.ant-table-thead tr th:nth-child(5),
  .evening-handle table thead.ant-table-thead tr th:nth-child(6),
  .evening-handle table thead.ant-table-thead tr th:nth-child(7),
  .evening-handle table thead.ant-table-thead tr th:nth-child(8),
  .evening-handle table thead.ant-table-thead tr th:nth-child(9),
  .evening-handle table thead.ant-table-thead tr th:nth-child(10) {
    min-width: 160px;
    padding: 0 10px;
  }
}