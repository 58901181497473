body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


/* styles.css */

.interested {
  background-color: #DFFFD6;
}

.not-interested {
  background-color: #FFD6D6;
}

.hopefully {
  background-color: #FFF7D6;
}

.not-picked {
  background-color: #FFEDD6;
}

.enrolled {
  background-color: #D6E3FF;
}

